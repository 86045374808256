var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container page-detail" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "所属项目", prop: "storeId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "20%" },
                  on: {
                    change: function ($event) {
                      return _vm.changeSelect(_vm.ruleForm.storeId)
                    },
                  },
                  model: {
                    value: _vm.ruleForm.storeId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "storeId", $$v)
                    },
                    expression: "ruleForm.storeId",
                  },
                },
                _vm._l(_vm.configData, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { value: item.value, label: item.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "模板名称", prop: "templateName" } },
            [
              _c("el-input", {
                staticStyle: { width: "20%" },
                attrs: { maxlength: "50", placeholder: "请输入模版名称" },
                model: {
                  value: _vm.ruleForm.templateName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "templateName", $$v)
                  },
                  expression: "ruleForm.templateName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "基础交付标准（单选）", prop: "optionVOS" } },
            _vm._l(_vm.ruleForm.optionVOS, function (item, index) {
              return _c(
                "el-row",
                {
                  key: index,
                  staticClass: "el-row-content",
                  attrs: { gutter: 16 },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 16 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "col-choice", attrs: { span: 2 } },
                        [_vm._v("选择项")]
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              maxlength: "50",
                              placeholder: "请输入标题",
                            },
                            model: {
                              value: item.title,
                              callback: function ($$v) {
                                _vm.$set(item, "title", $$v)
                              },
                              expression: "item.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 3 } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: item.isRequired,
                                callback: function ($$v) {
                                  _vm.$set(item, "isRequired", $$v)
                                },
                                expression: "item.isRequired",
                              },
                            },
                            _vm._l(_vm.isRequireList, function (ir, idx) {
                              return _c("el-option", {
                                key: idx,
                                attrs: { value: ir.value, label: ir.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "add-text", attrs: { span: 5 } },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addTabOption()
                                },
                              },
                            },
                            [_vm._v("添加")]
                          ),
                          _vm.ruleForm.optionVOS.length > 1
                            ? _c(
                                "span",
                                {
                                  staticClass: "del",
                                  on: {
                                    click: function ($event) {
                                      return _vm.delTabOption(index)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._l(item.optionSelectVOS, function (iSelet, idxByselet) {
                    return _c(
                      "el-row",
                      {
                        key: idxByselet,
                        staticStyle: { "margin-top": "8px" },
                        attrs: { gutter: 16 },
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { offset: 2, span: 5 } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                maxlength: "50",
                                placeholder: "请输入描述",
                              },
                              model: {
                                value: iSelet.itemDescription,
                                callback: function ($$v) {
                                  _vm.$set(iSelet, "itemDescription", $$v)
                                },
                                expression: "iSelet.itemDescription",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 3 } },
                          [
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请输入值",
                                  oninput:
                                    "value=value.replace(/[^0-9.]/g,'').replace(/\\.{2,}/g,'.').replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/,'$1$2.$3')",
                                },
                                model: {
                                  value: iSelet.itemValue,
                                  callback: function ($$v) {
                                    _vm.$set(iSelet, "itemValue", $$v)
                                  },
                                  expression: "iSelet.itemValue",
                                },
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("元"),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { staticClass: "add-text", attrs: { span: 5 } },
                          [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.addSelectVos(index)
                                  },
                                },
                              },
                              [_vm._v("添加")]
                            ),
                            item.optionSelectVOS.length > 1
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "del",
                                    on: {
                                      click: function ($event) {
                                        return _vm.delSelectVos(
                                          index,
                                          idxByselet
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            }),
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "其它交付标准（多选）",
                prop: "otherStandardVOS",
              },
            },
            _vm._l(_vm.ruleForm.otherStandardVOS, function (item, index) {
              return _c(
                "el-row",
                {
                  key: index,
                  staticClass: "el-row-content",
                  attrs: { gutter: 16 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { maxlength: "50", placeholder: "请输入描述" },
                        model: {
                          value: item.itemDescription,
                          callback: function ($$v) {
                            _vm.$set(item, "itemDescription", $$v)
                          },
                          expression: "item.itemDescription",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请输入值",
                            oninput:
                              "value=value.replace(/[^0-9.]/g,'').replace(/\\.{2,}/g,'.').replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/,'$1$2.$3')",
                          },
                          model: {
                            value: item.itemValue,
                            callback: function ($$v) {
                              _vm.$set(item, "itemValue", $$v)
                            },
                            expression: "item.itemValue",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("元")])],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "add-text", attrs: { span: 5 } },
                    [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.addTabOther()
                            },
                          },
                        },
                        [_vm._v("添加")]
                      ),
                      _vm.ruleForm.otherStandardVOS.length > 1
                        ? _c(
                            "span",
                            {
                              staticClass: "del",
                              on: {
                                click: function ($event) {
                                  return _vm.delTabOther(index)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "el-form-item",
            { staticStyle: { "margin-top": "76px" }, attrs: { align: "left" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }