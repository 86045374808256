<template>
  <div class="app-container page-detail">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      label-position="top"
    >
      <el-form-item label="所属项目" prop="storeId">
        <el-select
          style="width: 20%"
          v-model="ruleForm.storeId"
          @change="changeSelect(ruleForm.storeId)"
        >
          <el-option
            v-for="(item, index) in configData"
            :value="item.value"
            :label="item.label"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="模板名称" prop="templateName">
        <el-input
          style="width: 20%"
          v-model="ruleForm.templateName"
          maxlength="50"
          placeholder="请输入模版名称"
        ></el-input>
      </el-form-item>

      <el-form-item label="基础交付标准（单选）" prop="optionVOS">
        <el-row
          :gutter="16"
          v-for="(item, index) in ruleForm.optionVOS"
          :key="index"
          class="el-row-content"
        >
          <el-row :gutter="16">
            <el-col :span="2" class="col-choice">选择项</el-col>
            <el-col :span="5">
              <el-input
                style="width: 100%"
                v-model="item.title"
                maxlength="50"
                placeholder="请输入标题"
              ></el-input
            ></el-col>
            <el-col :span="3">
              <el-select v-model="item.isRequired">
                <el-option
                  v-for="(ir, idx) in isRequireList"
                  :value="ir.value"
                  :label="ir.label"
                  :key="idx"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="5" class="add-text"
              ><span @click="addTabOption()">添加</span>
              <span
                v-if="ruleForm.optionVOS.length > 1"
                class="del"
                @click="delTabOption(index)"
                >删除</span
              ></el-col
            >
          </el-row>

          <el-row
            :gutter="16"
            style="margin-top: 8px"
            v-for="(iSelet, idxByselet) in item.optionSelectVOS"
            :key="idxByselet"
          >
            <el-col :offset="2" :span="5">
              <el-input
                style="width: 100%"
                v-model="iSelet.itemDescription"
                maxlength="50"
                placeholder="请输入描述"
              ></el-input
            ></el-col>
            <el-col :span="3">
              <el-input
                style="width: 100%"
                v-model="iSelet.itemValue"
                placeholder="请输入值"
                oninput="value=value.replace(/[^0-9.]/g,'').replace(/\.{2,}/g,'.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"
              >
                <template slot="append">元</template>
              </el-input>
            </el-col>
            <el-col :span="5" class="add-text"
              ><span @click="addSelectVos(index)">添加</span>
              <span
                v-if="item.optionSelectVOS.length > 1"
                class="del"
                @click="delSelectVos(index, idxByselet)"
                >删除</span
              ></el-col
            >
          </el-row>
        </el-row>
      </el-form-item>
      <el-form-item label="其它交付标准（多选）" prop="otherStandardVOS">
        <el-row
          class="el-row-content"
          :gutter="16"
          v-for="(item, index) in ruleForm.otherStandardVOS"
          :key="index"
        >
          <el-col :span="5">
            <el-input
              style="width: 100%"
              v-model="item.itemDescription"
              maxlength="50"
              placeholder="请输入描述"
            ></el-input
          ></el-col>
          <el-col :span="3">
            <el-input
              style="width: 100%"
              v-model="item.itemValue"
              placeholder="请输入值"
              oninput="value=value.replace(/[^0-9.]/g,'').replace(/\.{2,}/g,'.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"
            >
              <template slot="append">元</template>
            </el-input>
          </el-col>
          <el-col :span="5" class="add-text"
            ><span @click="addTabOther()">添加</span>
            <span
              v-if="ruleForm.otherStandardVOS.length > 1"
              class="del"
              @click="delTabOther(index)"
              >删除</span
            ></el-col
          >
        </el-row>
      </el-form-item>
      <el-form-item align="left" style="margin-top: 76px">
        <el-button type="primary" @click="submitForm('ruleForm')"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  getStoreList,
  addTemplate,
  getTemplateDetail,
  editTemplate,
} from "@/api/ruge/gsPark/customerService/projectTemplate";
export default {
  name: "detail",
  data() {
    const validatOptionVOS = (rule, value, callback) => {
      this.ruleForm.optionVOS.map((item) => {
        if (!item.title) {
          return callback(new Error("填写错误"));
        }
        item.optionSelectVOS.map((iop) => {
          if (!iop.itemDescription || !iop.itemValue) {
            return callback(new Error("填写错误"));
          }
        });
      });
      callback();
    };
    const validatOtherVOS = (rule, value, callback) => {
      this.ruleForm.otherStandardVOS.map((item, index) => {
        if (!item.itemDescription && !item.itemValue && index == 0) {
          callback();
        } else if (!item.itemDescription || !item.itemValue) {
          return callback(new Error("填写错误"));
        }
      });
      callback();
    };
    return {
      currentState: "add",
      configData: [],
      isRequireList: [
        { value: 1, label: "必选" },
        { value: 0, label: "非必选" },
      ],
      opDefaultData: {
        optionType: "基础交付标准",
        title: "", //标题
        selectionType: 0,
        isRequired: 1, //是否必选
        optionSelectVOS: [
          {
            itemDescription: "",
            itemValue: "",
          },
        ],
      },
      otherAddData: {
        itemDescription: "",
        itemValue: "",
      },
      ruleForm: {
        storeId: "",
        templateName: "",
        optionVOS: [],
        otherStandardVOS: [],
      },
      storeName: null,
      rules: {
        storeId: [
          { required: true, message: "所属项目为必填项", trigger: "blur" },
        ],
        templateName: [
          { required: true, message: "模版名称为必填项", trigger: "blur" },
        ],
        optionVOS: [
          { required: true, validator: validatOptionVOS, trigger: "change" },
        ],
        otherStandardVOS: [{ validator: validatOtherVOS, trigger: "change" }],
      },
    };
  },
  created() {
    this.getTabStoreList();
    this.currentState = this.$route.query.viewStatus;
    if (this.currentState === "add") {
      this.ruleForm.optionVOS.push(
        JSON.parse(JSON.stringify(this.opDefaultData))
      );
      this.ruleForm.otherStandardVOS.push(
        JSON.parse(JSON.stringify(this.otherAddData))
      );
    } else if (this.currentState === "edit") {
      this.editTaberHandle();
    }
  },
  methods: {
    editTaberHandle() {
      getTemplateDetail({
        templateId: this.$route.query.templateId,
      }).then((res) => {
        if (res) {
          this.changeSelect(res.storeId);
          this.ruleForm.storeId = res.storeId;
          this.ruleForm.templateName = res.templateName;
          if (res.optionVOS.length > 0) {
            this.ruleForm.optionVOS = res.optionVOS;
          } else {
            this.addTabOption();
          }
          if (res.otherStandardVOS.length > 0) {
            this.ruleForm.otherStandardVOS = res.otherStandardVOS;
          } else {
            this.addTabOther();
          }
        }
      });
    },
    changeSelect(storeId) {
      this.configData.forEach((item) => {
        if (item.value == storeId) {
          this.storeName = item.label;
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitHandler();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitHandler() {
      let params = {
        ...this.ruleForm,
        ...{ storeName: this.storeName, preferentialVOS: [] },
      };
      if (this.currentState === "add") {
        addTemplate(params).then((res) => {
          if (res.code === 200) {
            this.$message.success("新建成功");
            this.$router.go(-1);
          }
        });
      } else if (this.currentState === "edit") {
        params = {
          ...params,
          ...{ id: this.$route.query.templateId },
        };
        editTemplate(params).then((res) => {
          if (res.code === 200) {
            this.$message.success("编辑成功");
            this.$router.go(-1);
            // this.editTaberHandle();
          }
        });
      }
    },
    addTabOption() {
      this.ruleForm.optionVOS.push(
        JSON.parse(JSON.stringify(this.opDefaultData))
      );
    },
    delTabOption(index) {
      this.ruleForm.optionVOS.splice(index, 1);
    },
    addSelectVos(index) {
      this.ruleForm.optionVOS[index].optionSelectVOS.push(
        JSON.parse(JSON.stringify(this.otherAddData))
      );
    },
    delSelectVos(index, idxByselet) {
      this.ruleForm.optionVOS[index].optionSelectVOS.splice(idxByselet, 1);
    },
    delTabOther(index) {
      this.ruleForm.otherStandardVOS.splice(index, 1);
    },
    addTabOther() {
      this.ruleForm.otherStandardVOS.push(
        JSON.parse(JSON.stringify(this.otherAddData))
      );
    },
    getTabStoreList() {
      getStoreList()
        .then((res) => {
          if (res.length > 0) {
            this.configData = res.map((item) => {
              return {
                value: item.storeId,
                label: item.storeName,
              };
            });
          } else {
            this.projectList = [];
          }
        })
        .catch(() => {
          this.projectList = [];
        });
    },
  },
};
</script>

<style lang="less" scoped>
.page-detail {
  margin: 32px;
  .el-row-content {
    margin-top: 16px;
    .add-text {
      color: #4374fa;
      span {
        cursor: pointer;
      }
      .del {
        margin-left: 16px;
      }
    }
    .col-choice {
      color: #606266;
      font-weight: 400;
      font-size: 14px;
      color: #606266;
      text-align: center;
    }
  }
}
</style>